/* eslint-disable import/prefer-default-export */
import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
// import MockAdapter from 'axios-mock-adapter'
import AxiosInterceptor from '../hooks/AxiosInterceptorHook'
import {apiURLs, AddPDPCookie, } from './Utility'
import {addDays, format, formatISO} from 'date-fns'

/**
 * Custome axios instance for usermanager api
 * @param {string} companyId
 * @param {string} iPlanetDirectoryProCookie
 * @param {string} bearerToken
 */
// defaults for bearer token and planetprocookie are set in loginHook
// after call to logLogin
const createTransactionMiddlewareAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: `${apiURLs.atmProjectBaseUrl}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true

  })

  AddPDPCookie(axiosInstance)

  const interceptor = AxiosInterceptor(axiosInstance)

  return {axiosInstance, interceptor}
}

const AtmProjectsAxiosInstance = createTransactionMiddlewareAxiosInstance()



export default {

  getProjects: (fromDateTime, toDateTime) => {

    // eslint-disable-next-line no-underscore-dangle
    // if (window._env.ATMPROJECTS_DATA) {

    //   // This sets the mock adapter on the default instance
    //   const mock = new MockAdapter(axios);

    //   // Mock any GET request to /users
    //   // arguments for reply are (status, data, headers)
    //   mock.onGet("/").reply(200,
    //     // eslint-disable-next-line no-underscore-dangle
    //     window._env.ATMPROJECTS_DATA
    //   );

    //   return axios.get("/")
    // }

    if(!fromDateTime)
      fromDateTime = addDays(new Date(new Date(Date.now()).setHours(0, 0, 0, 0)), -15)
    else
      fromDateTime.setHours(0,0,0,0)
    if(!toDateTime)
      toDateTime = addDays(new Date(new Date(Date.now()).setHours(23,59,59,997)), 15)
    else
      toDateTime.setHours(23,59,59,997)
    fromDateTime = formatISO(fromDateTime, {format:"extended",representation:'complete'}) //"yyyy-MM-ddTHH:mm:ss.fffzzz")
    toDateTime = formatISO(toDateTime, {format:"extended",representation:'complete'}) //"yyyy-MM-ddTHH:mm:ss.fffzzz")
    // const params = {fromDateTime, toDateTime}

    return AtmProjectsAxiosInstance.axiosInstance({
      method: 'GET',
      // params,
      url: `/${fromDateTime}/${toDateTime}`
    })

  },
  convert: (atmProjects) => {
    const url = '/convert'
    return AtmProjectsAxiosInstance.axiosInstance({
      url,
      method: 'POST',
      data:
        {atmProjects}
    }
    )
  },
  setJwtToken: jwtToken => {
    AtmProjectsAxiosInstance.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },

  setRequestInterceptor: (interceptor) => {
    AtmProjectsAxiosInstance.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    AtmProjectsAxiosInstance.interceptor.setResponseInterceptor(interceptor)
  },

  ready: () => {
    return (AtmProjectsAxiosInstance.axiosInstance
      && AtmProjectsAxiosInstance.axiosInstance.defaults
      && AtmProjectsAxiosInstance.axiosInstance.defaults.headers
      && AtmProjectsAxiosInstance.axiosInstance.defaults.headers.common
      && AtmProjectsAxiosInstance.axiosInstance.defaults.headers.common.Authorization
      && true)
  }
}

