/* eslint-disable import/prefer-default-export */
import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import MockAdapter from 'axios-mock-adapter'
import AxiosInterceptor from '../hooks/AxiosInterceptorHook'
import {apiURLs, AddPDPCookie, } from './Utility'

/**
 * Custome axios instance for usermanager api
 * @param {string} companyId
 * @param {string} iPlanetDirectoryProCookie
 * @param {string} bearerToken
 */
// defaults for bearer token and planetprocookie are set in loginHook
// after call to logLogin
const createTMAdhocAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: `${apiURLs.transactionMiddlewareBaseUrl}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true

  })

  AddPDPCookie(axiosInstance)

  const interceptor = AxiosInterceptor(axiosInstance)

  return {axiosInstance, interceptor}
}

const TMAdhocaAxiosInstance = createTMAdhocAxiosInstance()



export default {


  getTMWorkstationList: () => {      

      // eslint-disable-next-line no-underscore-dangle
      if (window._env.WORKSTATION_LIST) {

        // This sets the mock adapter on the default instance
        const mock = new MockAdapter(axios);
  
        // Mock any GET request to /users
        // arguments for reply are (status, data, headers)
        mock.onGet("/").reply(200,
          // eslint-disable-next-line no-underscore-dangle
          window._env.WORKSTATION_LIST
        );
  
        return axios.get("/")
      }

      return TMAdhocaAxiosInstance.axiosInstance({
        method: 'GET',        
        url: `/WorkstationIndex`
      })
    },
    getTMAdhocData: (wkstId) => {

    // eslint-disable-next-line no-underscore-dangle
    if (window._env.WORKSTATION_DATA) {

      // This sets the mock adapter on the default instance
      const mock = new MockAdapter(axios);

      // Mock any GET request to /users
      // arguments for reply are (status, data, headers)
      mock.onGet("/").reply(200,
        // eslint-disable-next-line no-underscore-dangle
        window._env.WORKSTATION_DATA
      );

      return axios.get("/")
    }

    //const params = wkstId ? {wkstId} : null
    return TMAdhocaAxiosInstance.axiosInstance({
      method: 'GET',      
      url: `/Workstation/${wkstId}`
    })

  },
  updateTMAdhocData: (wkstId,wkstData) => {
    const url = `/Workstation/${wkstId}`
    return TMAdhocaAxiosInstance.axiosInstance({
      url,
      method: 'POST',
      data:
        {...wkstData}
    }
    )
  },  
  setJwtToken: jwtToken => {
    TMAdhocaAxiosInstance.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },

  setRequestInterceptor: (interceptor) => {
    TMAdhocaAxiosInstance.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    TMAdhocaAxiosInstance.interceptor.setResponseInterceptor(interceptor)
  },

  ready: () => {
    return (TMAdhocaAxiosInstance.axiosInstance
      && TMAdhocaAxiosInstance.axiosInstance.defaults
      && TMAdhocaAxiosInstance.axiosInstance.defaults.headers
      && TMAdhocaAxiosInstance.axiosInstance.defaults.headers.common
      && TMAdhocaAxiosInstance.axiosInstance.defaults.headers.common.Authorization
      && true)
  }
}

