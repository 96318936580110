/* eslint-disable import/prefer-default-export */
import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import AxiosInterceptor from '../hooks/AxiosInterceptorHook'
import { apiURLs, AddPDPCookie, } from './Utility'

/**
 * Custome axios instance for usermanager api
 * @param {string} companyId
 * @param {string} iPlanetDirectoryProCookie
 * @param {string} bearerToken
 */
// defaults for bearer token and planetprocookie are set in loginHook
// after call to logLogin
const createATMVideoAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: `${apiURLs.atmvideoUrl}`,
    headers: {
      'Content-Type': 'video/mp4'
    },
    withCredentials: true,
    responseType: 'blob'
  })

  AddPDPCookie(axiosInstance)

  const interceptor = AxiosInterceptor(axiosInstance)

  return { axiosInstance, interceptor }
}

const AtmVideoAxiosInstance = createATMVideoAxiosInstance()



export default {

  getATMVideoContent: videoName =>
    AtmVideoAxiosInstance.axiosInstance({
      url: `/atmvideo/${videoName}`,
      method: 'GET'
    }),
  getATMVideoList: () =>
    AtmVideoAxiosInstance.axiosInstance({
      url: `/list`,
      method: 'GET',
      responseType: 'json'
    }),
  setJwtToken: jwtToken => {
    AtmVideoAxiosInstance.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },

  setRequestInterceptor: (interceptor) => {
    AtmVideoAxiosInstance.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    AtmVideoAxiosInstance.interceptor.setResponseInterceptor(interceptor)
  },

  ready: () => {
    return (AtmVideoAxiosInstance.axiosInstance
      && AtmVideoAxiosInstance.axiosInstance.defaults
      && AtmVideoAxiosInstance.axiosInstance.defaults.headers
      && AtmVideoAxiosInstance.axiosInstance.defaults.headers.common
      && AtmVideoAxiosInstance.axiosInstance.defaults.headers.common.Authorization
      && true)
  }
}

