/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import AxiosInterceptor from '../hooks/AxiosInterceptorHook';
import {apiURLs, getWithCredentials} from './Utility'



const createUserManagerAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: apiURLs.authenticateUrl,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: getWithCredentials()
  });
  const interceptor = AxiosInterceptor(axiosInstance)

  return {axiosInstance, interceptor}
}

const keepAliveAxios = createUserManagerAxiosInstance()

export default {
  keepAlive: () => {
    const url = '/keepalive'
    return keepAliveAxios.axiosInstance({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })
  },
  setRequestInterceptor: (interceptor) => {
    keepAliveAxios.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    keepAliveAxios.interceptor.setResponseInterceptor(interceptor)
  },
  setJwtToken: jwtToken => {
    keepAliveAxios.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },
};
