/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import { apiURLs, getWithCredentials ,getIPlanetProCookieName} from './Utility'

/**
 * Custom axios instance for forgeRock
 * @param {string} companyId
 * @param {string} userId
 * @param {string} password
 */
export const authenticateAxiosInstance = axios.create({
  baseURL: apiURLs.authenticateUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: getWithCredentials()
});

export default {
  authenticate: (companyId, userId, password) =>
    authenticateAxiosInstance({
      url: '/authenticate',
      method: 'POST',
      data: {
        companyId, userId, password
      }
    }),
    login: () =>
      authenticateAxiosInstance({
        url: '/login',
        method: 'GET',
      })
    ,
    setPDPCookie: iPlanetDirectoryProCookie => {
      authenticateAxiosInstance.defaults.headers[getIPlanetProCookieName()] = iPlanetDirectoryProCookie
    },
    logout: () =>
    authenticateAxiosInstance({
      url: '/logout',
      method: 'POST'
    })
};
