/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import AxiosInterceptor from '../hooks/AxiosInterceptorHook'
import {apiURLs, getWithCredentials, AddPDPCookie, getIPlanetProCookieName} from './Utility'

/**
 * Custome axios instance for usermanager api
 * @param {string} companyId
 * @param {string} iPlanetDirectoryProCookie
 * @param {string} bearerToken
 */
// defaults for bearer token and planetprocookie are set in loginHook
// after call to logLogin
const createUserManagerAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: `${apiURLs.userManagerBaseUrl}/companies`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: getWithCredentials()
  })

  AddPDPCookie(axiosInstance)

  const interceptor = AxiosInterceptor(axiosInstance)

  return {axiosInstance, interceptor}
}

const userManagerAxiosInstance = createUserManagerAxiosInstance()
const userManagerAxiosInstanceUsers = createUserManagerAxiosInstance()


export default {
  getUsers: (companyId) => {
    if (companyId) {
      return userManagerAxiosInstance.axiosInstance({
        baseURL: `${apiURLs.userManagerBaseUrl}/companies/${companyId}`,
        url: `/users`,
        method: 'GET'
      })

    }
    return userManagerAxiosInstance.axiosInstance({
      url: `/users`,
      method: 'GET'
    })

  },
  getUser: (userId, returnRoleInfo = null) =>
    userManagerAxiosInstance.axiosInstance({
      url: `/users/user/get`.concat((returnRoleInfo) ? '?returnRoleInfo=true' : ''),
      method: 'POST',
      data: {userId}
    }),
  addOrUpdateUser: (isAdd, user, returnRoleInfo = null, preventRoleUpdate = false) => {
    const url = `/users/user/${isAdd ? 'add' : 'update'}`.concat((returnRoleInfo) ? '?returnRoleInfo=true' : '')
    return userManagerAxiosInstance.axiosInstance({
      url,
      method: 'POST',
      data: {
        user,
        preventRoleUpdate
      }
    })
  },

  getUserReport: () =>
    userManagerAxiosInstanceUsers.axiosInstance({
      url: `/users/userreport`,
      method: 'GET'
    }
    ),

  setJwtToken: jwtToken => {
    userManagerAxiosInstance.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
    userManagerAxiosInstanceUsers.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },
  setPDPCookie: iPlanetDirectoryProCookie => {
    userManagerAxiosInstance.axiosInstance.defaults.headers[getIPlanetProCookieName()] = iPlanetDirectoryProCookie
  },
  setCompanyId: companyId => {
    userManagerAxiosInstance.axiosInstance.defaults.baseURL = `${apiURLs.userManagerBaseUrl}/companies/${companyId}`
    return userManagerAxiosInstance.axiosInstance.defaults.baseURL
  },
  setRequestInterceptor: (interceptor) => {
    userManagerAxiosInstance.interceptor.setRequestInterceptor(interceptor)
    userManagerAxiosInstanceUsers.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    userManagerAxiosInstance.interceptor.setResponseInterceptor(interceptor)
    userManagerAxiosInstanceUsers.interceptor.setResponseInterceptor(interceptor)
  },

  ready: () => {
    return (userManagerAxiosInstance.axiosInstance
      && userManagerAxiosInstance.axiosInstance.defaults
      && userManagerAxiosInstance.axiosInstance.defaults.headers
      && userManagerAxiosInstance.axiosInstance.defaults.headers.common
      && userManagerAxiosInstance.axiosInstance.defaults.headers.common.Authorization
      && userManagerAxiosInstanceUsers.axiosInstance
      && userManagerAxiosInstanceUsers.axiosInstance.defaults
      && userManagerAxiosInstanceUsers.axiosInstance.defaults.headers
      && userManagerAxiosInstanceUsers.axiosInstance.defaults.headers.common
      && userManagerAxiosInstanceUsers.axiosInstance.defaults.headers.common.Authorization
      && true)
  }
}
