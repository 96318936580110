/* eslint-disable no-alert */
import { useReducer, useCallback } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import * as actionTypes from './ActionTypes'
import {
  getLoggedInUserRoles,
  getSharedCookieDomain,
  getErrorResponse,
  getIPlanetProCookieName  
} from '../utils/Utility'
// import forgeRockAuthenticateAxios from '../utils/ForgeRockAxios'
import authenticateAxios from '../utils/AuthenticateAxios'
import UserManagerAxios from '../utils/UserManagerAxios'
import CompanyManagerAxios from '../utils/CompanyManagerAxios'
import KeepAliveAxios from '../utils/KeepAliveAxios'
import atmProjectsAxios from '../utils/AtmProjectAxios'
import atmVideoAxios from '../utils/AtmVideoAxios'
import TMSyncDataAxios from '../utils/TMSyncDataAxios'
import TMAdhocAxios from '../utils/TMAdhocAxios'

/**
 * initial state object used by useReducer
 */
const initialState = {
  loading: false,
  error: null,
  user: null,
  identifier: null,
  resetSessionTimer: null
}

const cookieDomain = getSharedCookieDomain() === 'localhost' ? null : getSharedCookieDomain()
/**
 * Function being used by useReducer
 * @param {object} currState - current state object
 * @param {object} action - action with type and payload
 */
const httpReducer = (currState, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE_USER_REQUEST_SENT:
    case actionTypes.LOGOUT_USER_REQUEST_SENT:
      return {
        loading: true,
        error: null
      }
    case actionTypes.AUTHENTICATE_USER_RECVD:
      return {
        ...currState,
        loading: false,
        error: null,
        user: action.responseData
      }
    case actionTypes.LOGOUT_USER_RECVD:
      return {
        ...currState,
        loading: false,
        error: null,
        user: action.responseData
      }
    case actionTypes.AUTHENTICATE_SET_RESETSESSIONTIMER:
      return { ...currState, resetSessionTimer: action.resetSessionTimer }
    case actionTypes.CLEAR_STATE:
      return initialState
    case actionTypes.ERROR:
      return { loading: false, error: action.errorResponse }
    default:
      return new Error(`Action type ${action.type} not handled.`)
  }
}

/**
 * Custom hook for handling user authentication and authorization
 */
const useLoginHook = () => {
  const [httpState, dispatch] = useReducer(httpReducer, initialState)

  /**
   * Clears the state
   */
  const clearUserInfo = useCallback(() => {
    dispatch({ type: actionTypes.CLEAR_STATE })
  }, [])

  /**
   * Logs user login activity in database
   */
  const logLogin = useCallback(userInfo => {
    let source = {}
    const { CancelToken } = axios
    source = CancelToken.source() // eslint-disable-next-line no-param-reassign

    dispatch({ type: actionTypes.AUTHENTICATE_USER_REQUEST_SENT })
    
    authenticateAxios.login()
      .then(res1 => {
        // update state with response
        const returnVal = res1.data // .result
        // eslint-disable-next-line camelcase
        const { accessToken, user, company } = returnVal
        // if the logging worked returbVal will equal -1
        if (returnVal && /* returnVal.result */ returnVal.logResult === -1) {
         
          UserManagerAxios.setJwtToken(accessToken)
          UserManagerAxios.setCompanyId(user.companyId)
          atmProjectsAxios.setJwtToken(accessToken)
          atmVideoAxios.setJwtToken(accessToken)         
                    
          CompanyManagerAxios.setJwtToken(accessToken)
          
          KeepAliveAxios.setJwtToken(accessToken)

          TMSyncDataAxios.setJwtToken(accessToken)

          TMAdhocAxios.setJwtToken(accessToken)

          const newUserInfo = {
            ...user,
            companyName: company.companyName,
            firstName: user.firstName,
            lastName: user.lastName,
            securityEquals: user.roles,
            userRoles: getLoggedInUserRoles(user.roles),
            token: accessToken,
            applicationId: company.applicationId
          }
          dispatch({
            type: actionTypes.AUTHENTICATE_USER_RECVD,
            responseData: { ...newUserInfo }
          })        
        }
        else {          
          const error = "unexpected login response"          
          dispatch({ type: actionTypes.ERROR, errorResponse: error })
        }
      })
      .catch(err => {        
        const error = getErrorResponse(err,'Login')        
        dispatch({ type: actionTypes.ERROR, errorResponse: error })
      })    
    return () => {
      if (source) source.cancel()
    }
  }, [])

  const setResetSessionTimer = useCallback((resetSessionTimer) => {
    dispatch({ type: actionTypes.AUTHENTICATE_SET_RESETSESSIONTIMER, resetSessionTimer})
  }, [])
    /**
   * Authenticates user with ForgeRock
   * Returns iPlanetDirectoryPro cookie
   */
  const authenticateUser = useCallback((companyId, userId, password) => {
    let source = {}
    const { CancelToken } = axios
    source = CancelToken.source()

    dispatch({ type: actionTypes.AUTHENTICATE_USER_REQUEST_SENT })

    authenticateAxios
      .authenticate(companyId, userId, password)
      .then(res => {

        localStorage.setItem('companyId', companyId)
        localStorage.setItem('userId', userId)
        const userInfo = { companyId, userId }
        logLogin(userInfo)
      })
      .catch(err => {
        dispatch({ type: actionTypes.ERROR, errorResponse: getErrorResponse(err,'Login') })
      })

    return () => {
      if (source) source.cancel()
    }
  }, [])


  /**
   * Logout user with ForgeRock
   */
  const logoutUser = useCallback(() => {
    let source = {}
    const { CancelToken } = axios
    source = CancelToken.source()

    dispatch({ type: actionTypes.LOGOUT_USER_REQUEST_SENT })

    authenticateAxios
      .logout()
      .then(res => {

        //Remove the IPlanetDirectoryCookie from the browser after logout.
        clearIPlanetDirectoryCookie();

        dispatch({
          type: actionTypes.LOGOUT_USER_RECVD,
          responseData: res.data
        })
      })
      .catch(err => {
        clearIPlanetDirectoryCookie();
        dispatch({ type: actionTypes.ERROR, errorResponse: getErrorResponse(err,'Login') })
      })

    return () => {
      if (source) source.cancel()
    }
  }, [])

  const clearIPlanetDirectoryCookie = useCallback(() => {
    const cookieDomain = getSharedCookieDomain() === 'localhost' ? null : getSharedCookieDomain()
    Cookies.remove(getIPlanetProCookieName(), { path: '', domain: cookieDomain })
  }, [])


  /**
   * Returns props, functions and methods to use for derived page/component
   * Sample Usage: const { isAuthenticating, userInfo, error, authenticateUser, clearUserInfo } = useLoginHook()
   */
  return {
    isAuthenticating: httpState.loading,
    userInfo: httpState.user,
    userInfoError: httpState.error,
    authenticateUser,
    logLogin,
    clearUserInfo,
    logoutUser,
    setResetSessionTimer,
  }
}

export default useLoginHook
