/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import AxiosInterceptor from '../hooks/AxiosInterceptorHook'
import {apiURLs, getWithCredentials, AddPDPCookie, getIPlanetProCookieName} from './Utility'



/**
 * Custome axios axiosInstance for usermanager api
 * @param {string} iPlanetDirectoryProCookie
 * @param {string} bearerToken
 */
// defaults for bearer token and planetprocookie are set in loginHook
// after call to logLogin
const createCompanyManagerAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: apiURLs.userManagerBaseUrl,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: getWithCredentials()
  })

  AddPDPCookie(axiosInstance)

  const interceptor = AxiosInterceptor(axiosInstance)

  return {axiosInstance, interceptor}
}

const companyManagerAxiosInstance = createCompanyManagerAxiosInstance()


export default {
  getUserCompanyRoles: companyId =>
    companyManagerAxiosInstance.axiosInstance({
      url: `/companies/${companyId}/roles`,
      method: 'GET'
    }),
  getCompanyRoles: companyId =>
    companyManagerAxiosInstance.axiosInstance({
      url: `/companies/${companyId}/companyroles`,
      method: 'GET'
    }),
  getApplicationRoles: applicationId =>
    companyManagerAxiosInstance.axiosInstance({
      url: 'companies/applicationroles',
      method: 'POST',
      data: {applicationId}
    }),
  getAvailableRoles: applicationId =>
    companyManagerAxiosInstance.axiosInstance({
      url: 'companies/availableroles',
      method: 'POST',
      data: {applicationId}
    }),
  getCompaniesList: applicationId =>
    companyManagerAxiosInstance.axiosInstance({
      url: '/companieslist',
      method: 'POST',
      data: {applicationId}
    }),
  getCompany: companyId =>
    companyManagerAxiosInstance.axiosInstance({
      url: `/companies/${companyId}`,
      method: 'GET'
    }),

  addOrUpdateCompany: (isAdd, company) => {
    const url = `/companies/company/${isAdd ? 'add' : 'update'}`

    return companyManagerAxiosInstance.axiosInstance({
      url,
      method: 'POST',
      data: company
    })
  },

  validateCompanyName: (company) => {
    const url = `/companies/company/validatecompanyname`

    return companyManagerAxiosInstance.axiosInstance({
      url,
      method: 'POST',
      data: company
    })
  },

  validateAlternateID: (company) => {
    const url = `/companies/company/validatealternateid`

    return companyManagerAxiosInstance.axiosInstance({
      url,
      method: 'POST',
      data: company
    })
  },
  getAlternateIds: () =>
    companyManagerAxiosInstance.axiosInstance({
      url: `/companies/alternateids`,
      method: 'GET'
    }),

  setJwtToken: jwtToken => {
    companyManagerAxiosInstance.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },
  setPDPCookie: iPlanetDirectoryProCookie => {
    companyManagerAxiosInstance.axiosInstance.defaults.headers[
      getIPlanetProCookieName()
    ] = iPlanetDirectoryProCookie
  },
  setRequestInterceptor: (interceptor) => {
    companyManagerAxiosInstance.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    companyManagerAxiosInstance.interceptor.setResponseInterceptor(interceptor)
  },


}
